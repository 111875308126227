import request from 'axios'

const base_url = 'https://api.user.supply';
// const tokens = {access_toke: "", refresh_token: ""};
const login_details = {
  email: 'niv@gmail.com',
  password: '12345678niv',
};

function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2)
    return parts.pop().split(";").shift();
}


const login = async () => {
  const r = await request.post(base_url+'/login', login_details,{ xhrFields: { withCredentials: true }});
  console.log(r.status);
  console.log(r.data);
  console.log(r.headers);
}

const login2 = async () => {
  await fetch('https://api.user.supply/login', {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  credentials: 'include',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify({
    email: 'niv@gmail.com',
    password: '12345678niv',
  })
})
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    });
}

const check_login = async () => {
  const config = { withCredentials: true };
  const r = await request.get(base_url+'/auth/is_logged', config);
  console.log(r.status);
  console.log(r.data);
  console.log(r.headers);
}

const check_login2 = async () => {
  await fetch('https://api.user.supply/auth/is_logged', {
  method: 'GET',
  credentials: 'include',
  headers: {'Content-Type': 'application/json'}
})
    .then((data) => {
      console.log(data);
    });
}

const edit_profile = async () => {
  const config = { withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')} };
  const r = await request.post(base_url+'/auth/edit_profile', {}, config);
  console.log(r.status);
  console.log(r.data);
  console.log(r.headers);
}

const edit_profile2 = async () => {
  await fetch('https://api.user.supply/auth/edit_profile', {
  method: 'POST',
  credentials: 'include',
  headers: {'Content-Type': 'application/json','X-CSRF-TOKEN': getCookie('csrf_access_token')},
  body: JSON.stringify({})
})
    .then((data) => {
      console.log(data);
    });
}

const token_refresh = async () => {
  const config = { withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')} };
  const r = await request.post(base_url+'/token/refresh', {}, config);
  console.log(r.status);
  console.log(r.data);
  console.log(r.headers);
}

const token_refresh2 = async () => {
  await fetch('https://api.user.supply/token/refresh', {
  method: 'POST',
  credentials: 'include',
  headers: {'Content-Type': 'application/json','X-CSRF-TOKEN': getCookie('csrf_refresh_token')},
  body: JSON.stringify({})
})
    .then((data) => {
      console.log(data);
    });
}


export  {login,login2,check_login,check_login2,edit_profile,edit_profile2,token_refresh,token_refresh2};
