import React from 'react';
import {login,login2,check_login,check_login2,edit_profile,edit_profile2,token_refresh,token_refresh2} from './apiCalls';

function App() {

  const handleClick = async ()=>{
    console.log("Tests with Fetch");
    console.log("Login");
    await login2();
    console.log("Check login");
    await check_login2();
    console.log("Edit profile");
    await edit_profile2();
    console.log("Refresh token");
    await token_refresh2();
    console.log("Check login again");
    await check_login2();
    console.log("Edit profile again");
    await edit_profile2();

    console.log("Tests with Axios");
    console.log("Login");
    await login();
    console.log("Check login");
    await check_login();
    console.log("Edit profile");
    await edit_profile();
    console.log("Refresh token");
    await token_refresh();
    console.log("Check login again");
    await check_login();
    console.log("Edit profile again");
    await edit_profile();

  }

  return (
    <div>
      <button onClick={handleClick} >Login</button>
    </div>
  );
}

export default App;
